<template>
  <div class="summary">
    <div class="summary__header">
      <h3 class="summary__header-heading">{{ title }}</h3>
    </div>
    <div class="summary__card">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "SummaryCard",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.summary {
  &__header {
    width: 100%;
    background: #ebebeb;
    border-bottom: 2px solid $primary;
    height: 50px;

    &-heading {
      margin-top: 0;
      letter-spacing: 2px;
      color: #000;
      padding: 0 0.7rem;
      font-weight: 600;
      text-transform: capitalize;
      text-align: left;
      font-size: 1rem;
      max-width: 600px;
    }
  }
  &__card {
    width: 100%;
    background: #fff;
    border: 1px solid #ddd;
    padding: 20px;
    height: 100%;
  }
}
</style>