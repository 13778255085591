<template>
  <div class="stat-item">
    <div class="stat-item__icon">
      <i :class="icon"></i>
    </div>
    <div class="stat-item__info">
      <span class="stat-item__info-label">{{ label }}</span>
      <span class="stat-item__info-value">{{ value }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "SummaryCardSalesItem",
  props: {
    icon: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.stat-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;

  &__icon {
    font-size: 16px;
    color: #666;
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    &-label {
      font-size: 0.8rem;
      color: #666;
    }

    &-value {
      font-size: 0.85rem;
      font-weight: bold;
      color: #333;
    }
  }
}
@media (min-width: $breakpoint-md-min) {
  .stat-item__info-label {
    font-size: 0.9rem;
  }
}
</style>