import { createStore } from "vuex";
import { auth } from "./auth.module";
import { global } from "./global.module";
import { dashboardRaces } from "./dashboardRaces.module";
import { dashboardCharts } from "./dashboardCharts.module";

const store = createStore({
  modules: {
    auth,
    global,
    dashboardRaces,
    dashboardCharts
  },
});

export default store;
