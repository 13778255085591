<template>
  <div class="summary__details">
    <div
      v-for="item in statsItems"
      :key="item.id"
      class="stats-item"
      :style="{ backgroundColor: item.color }"
    >
      <div class="stats-item__container">
        <div class="stats-item__label">{{ item.label }}</div>
        <div class="stats-item__value">{{ item.value }}</div>
      </div>
      <div class="stats-item__container">
        <div class="stats-item__icon">
          <q-icon :name="item.icon" size="md" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "SummaryCardStats",
  props: {
    race: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState("dashboardCharts", ["totalViews"]),
    ...mapState("dashboardRaces", ["totalPhotosInRace"]),
    statsItems() {
      if (!this.race.ordersSummary) {
        return [];
      }
      return [
        {
          id: 1,
          label: this.$t("dashboard.main.albumViews"),
          value: this.totalViews || 0,
          icon: "visibility",
          color: "#ff69b4",
        },
        {
          id: 2,
          label: this.$t("dashboard.main.totalOrders"),
          value: this.race.ordersSummary.totalOrders || 0,
          icon: "shopping_cart",
          color: "#9467bd",
        },
        {
          id: 3,
          label: this.$t("dashboard.main.totalAlbumsSold"),
          value: this.race.ordersSummary.totalAlbumsSold || 0,
          icon: "collections",
          color: "#4fc3f7",
        },
        {
          id: 4,
          label: this.$t("dashboard.main.totalPhotosInRace"),
          value: `${this.totalPhotosInRace}`,
          icon: "photo",
          color: "#43a047",
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.summary__details {
  display: grid;
  justify-content: space-evenly;
  gap: 10px;
  grid-template-columns: 1fr;
}

.stats-item {
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  display: flex;
  align-items: center;

  div:has(.stats-item__label) {
    flex-grow: 1;
    padding: 20px 12px 10px 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .stats-item__container {
    height: 100%;
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__label {
    flex-grow: 1;
    font-size: 0.9rem;
    color: #fff;
  }

  &__value {
    margin-top: 10px;
    font-size: 1.7rem;
    font-weight: bold;
    color: #fff;
  }

  &__icon {
    background-color: rgba($color: #fff, $alpha: 0.15);
    color: #fff;
    width: 60px;
    display: flex;
    justify-content: center;
    align-self: stretch;
    i {
      align-self: center;
    }
  }
}

.stats-item:hover {
  transform: scale(1.02);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  transition: 0.1s all ease-in;
}

@media (min-width: $breakpoint-sm-min) {
  .summary__details {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: $breakpoint-lg-min) {
  .summary__details {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .stats-item {
    &__icon {
      width: 85px;
    }
  }
}
</style>
