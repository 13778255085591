<template>
  <div class="orders__table">
    <div class="orders__table-header">
      <h2 class="orders__table-title">
        {{ $t("dashboard.ordersTable.title") }}
      </h2>
      <div class="orders__table-summary">
        <span class="summary-label">
          {{ $t("dashboard.ordersTable.sumCommissions") }}:&nbsp;
        </span>
        <span class="summary-value">
          {{ this.organizatorCommissionPerCurrency }}</span>
      </div>
    </div>

    <q-spinner
      v-if="ordersLoading"
      class="q-my-lg self-center"
      color="primary"
      size="3em"
    />

    <div class="orders__table-wrapper">
      <q-table
        v-if="!ordersLoading && orders.length > 0"
        row-key="orderId"
        :rows="orders"
        :columns="ordersColumns"
        :loading="ordersLoading"
        @request="onRequest"
        hide-bottom
      >
        <template v-slot:body="props">
          <q-tr :props="props">
            <q-td v-for="col in ordersColumns" :key="col.name" :props="props">
              <div class="orders__table-row">
                <template v-if="col.field === 'itemLinks'">
                  <div
                    v-for="(link, index) in props.row.itemLinks"
                    :key="index"
                  >
                    <q-icon name="link" size="16px" class="q-mr-xs" />
                    <a
                      :href="link.link"
                      target="_blank"
                      class="orders__table-link text-primary"
                      >{{ link.label }}</a
                    >
                  </div>
                </template>
                <template v-else>
                  {{ props.row[col.field] }}
                </template>
              </div>
            </q-td>
          </q-tr>
        </template>

        <template v-slot:no-data>
          <div class="full-width row flex-center q-gutter-md">
            <span>
              {{ $t("dashboard.ordersTable.noOrders") }}
            </span>
          </div>
        </template>
      </q-table>

      <q-pagination
        class="q-py-md q-px-lg"
        v-model="pagination.page"
        :max="pagesNumber"
        :direction-links="true"
        :boundary-links="true"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "OrdersTable",
  props: {
    raceId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      pagination: {
        page: 1,
        rowsPerPage: 10,
        rowsNumber: 0,
        sortBy: "orderDate",
        descending: false,
      },
    };
  },
  computed: {
    ...mapState("dashboardRaces", [
      "ordersData",
      "ordersLoading",
      "ordersError",
      "ordersTableItemCount",
      "organizatorCommissionPerCurrency",
    ]),
    orders() {
      return this.ordersData || [];
    },
    pagesNumber() {
      return Math.ceil(this.ordersTableItemCount / this.pagination.rowsPerPage);
    },
    ordersColumns() {
      return [
        {
          name: "orderId",
          label: this.$t("dashboard.ordersTable.orderId"),
          align: "left",
          field: "orderId",
          sortable: true,
          headerClass: "text-left",
        },
        {
          name: "orderDate",
          label: this.$t("dashboard.ordersTable.orderDate"),
          align: "center",
          field: "orderDate",
          sortable: true,
          headerClass: "text-left",
        },
        {
          name: "itemLinks",
          label: this.$t("dashboard.ordersTable.albumLink"),
          align: "center",
          field: "itemLinks",
          sortable: false,
          headerClass: "text-center",
        },
        {
          name: "orderAmount",
          label: this.$t("dashboard.ordersTable.orderAmount"),
          align: "center",
          field: "orderAmount",
          sortable: true,
          headerClass: "text-center",
        },
        {
          name: "coupon",
          label: this.$t("dashboard.ordersTable.coupon"),
          align: "center",
          field: "coupon",
          sortable: true,
          headerClass: "text-center",
        },
        {
          name: "currency",
          label: this.$t("dashboard.ordersTable.currency"),
          align: "center",
          field: "currency",
          sortable: true,
          headerClass: "text-center",
        },
        {
          name: "authorCommission",
          label: this.$t("dashboard.ordersTable.authorCommission"),
          align: "center",
          field: "authorsCommission",
          sortable: true,
          headerClass: "text-center",
        },
        {
          name: "paygateCommision",
          label: this.$t("dashboard.ordersTable.paygateCommission"),
          align: "center",
          field: "paygateCommission",
          sortable: true,
          headerClass: "text-center",
        },
        {
          name: "afterFeesTotal",
          label: this.$t("dashboard.ordersTable.afterFeesTotal"),
          align: "right",
          field: "organizerCommission",
          sortable: true,
          headerClass: "text-right",
        },
      ];
    },
  },
  watch: {
    raceId: {
      immediate: true,
      handler() {
        this.fetchData();
      },
    },
    "pagination.page"(newPage, oldPage) {
      if (newPage !== oldPage) {
        this.fetchData();
      }
    },
    "pagination.rowsPerPage"(newRowsPerPage, oldRowsPerPage) {
      if (newRowsPerPage !== oldRowsPerPage) {
        this.pagination.page = 1;
        this.fetchData();
      }
    },
  },
  methods: {
    ...mapActions("dashboardRaces", ["fetchRaceOrdersData"]),
    async fetchData() {
      const { page, rowsPerPage } = this.pagination;

      await this.fetchRaceOrdersData({
        raceId: this.raceId,
        page,
        perPage: rowsPerPage,
      });

      this.pagination.rowsNumber = this.ordersTableItemCount;
    },
    async onRequest(props) {
      this.pagination = {
        ...props.pagination,
        rowsNumber: this.ordersTableItemCount,
      };

      await this.fetchData();
    },
  },
};
</script>

<style lang="scss" scoped>
.orders__table {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  &-wrapper {
    padding: 0 0 8px 0;
  }

  &-link {
    text-decoration: none;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid #e9ecef;
  }

  &-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: $secondary;
    margin: 0;
  }

  &-summary {
    display: flex;

    .summary-label {
      font-size: 1rem;
      color: $secondary;
      margin-right: 8px;
    }

    .summary-value {
      font-size: 1.1rem;
      font-weight: 600;
      color: $positive;
    }
  }
}

.q-table {
  th {
    font-weight: 600;
    background-color: #f1f3f5;
    color: #495057;
    font-size: 0.8rem;
    padding: 12px 0px 12px 12px;
    border-bottom: 2px solid #dee2e6;

    &.text-left {
      text-align: left;
    }

    &.text-center {
      text-align: center;
    }

    &.text-right {
      text-align: right;
    }

    &:hover {
      background-color: #e9ecef;
    }
  }

  &__card {
    box-shadow: none;
  }

  tbody td {
    padding: 12px 8px;
    font-size: 0.7rem;
    color: #212529;
  }

  tbody tr:hover {
    background-color: #f8f9fa;
  }
}

.q-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

@media (max-width: $breakpoint-xs-max) {
  .orders__table {
    &-header {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }
    &-title {
      font-size: 1.3rem;
    }
    .summary-label {
      font-size: 0.9rem;
    }
  }
}
</style>