<template>
  <div class="races">
    <div class="races__wrapper q-pa-md">
      <h2 class="text-h3 q-my-sm">{{ $t("navigation.myRaces") }}</h2>
      <p class="text-subtitle1 q-my-lg">{{ $t("races.description") }}</p>

      <div v-if="loading" class="races-loading">
        <q-spinner class="q-mx-md q-mb-md" color="primary" size="3em" />
        <p>
          {{ $t("races.loading") }}
        </p>
      </div>

      <div
        v-if="!loading && races.length === 0"
        class="races-loading__error text-subtitle1"
      >
        {{ $t("races.noRacesMessage") }}
      </div>

      <div v-if="!loading && !error && races.length > 0">
        <q-card
          v-for="race in races"
          :key="race.id"
          class="q-mb-md bg-grey-1"
          flat
          bordered
        >
          <div class="row no-wrap races__card">
            <div class="col-xs-12 col-sm-4 races__card-img q-py-sm">
              <q-img :src="race.thumbnail" class="full-height" />
            </div>
            <div class="col-xs-12 col-sm-8 races__card-detail">
              <div>
                <div class="text-h6 q-mb-md text-weight-bold">
                  {{ race.name }}
                </div>
                <div class="text-subtitle2">
                  {{ $t("races.eventStart") }}: {{ formatDate(race.startDate) }}
                </div>
              </div>
              <q-btn
                :race="race"
                color="primary"
                class="q-mt-sm races__card-button"
                :label="$t('races.button')"
                @click="goToDashboard(race.id)"
              />
            </div>
          </div>
        </q-card>
      </div>
    </div>
  </div>
</template>

<script>
import { date } from "quasar";
import { mapActions, mapState } from "vuex";
import { Notify } from "quasar";

export default {
  name: "DashboardRacesListPage",
  computed: {
    ...mapState({
      loading: (state) => state.dashboardRaces.loading,
      error: (state) => state.dashboardRaces.error,
      races: (state) => state.dashboardRaces.races,
    }),
  },
  mounted() {
    this.fetchUserRaces();
  },
  async created() {
    if (!this.races) {
      try {
        await this.fetchUserRaces();
      } catch (error) {
        Notify.create({
          type: "negative",
          message: this.$t("error.fetchRaces"),
          position: "bottom",
        });
      }
    }
  },
  methods: {
    ...mapActions("dashboardRaces", ["fetchUserRaces"]),
    formatDate(dateString) {
      return date.formatDate(dateString, "YYYY/MM/DD");
    },
    goToDashboard(id) {
      this.$router.push(`/my-races/${id}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.races {
  background: #fff;
  width: 100%;
  min-height: calc(100vh - 50px);
  margin: 0 auto;
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
  &-loading,
  &-loading__error {
    width: 300px;
    padding: 2rem 1rem 1rem 1rem;
    margin: 0 auto;
    text-align: center;
    &__error {
      border-bottom: 2px solid $primary;
    }
  }
  &__wrapper {
    width: 100%;
    max-width: 800px;
  }
  &__card {
    max-width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    &-img {
      margin: 0 auto;
      width: 100%;
      height: 250px;
    }
    &-detail {
      display: flex;
      flex-direction: column;
      padding: 10px;
    }
    &-button {
      width: 100%;
      max-width: 225px;
      align-self: center;
    }
  }
}
@media (min-width: $breakpoint-xs-max) {
  .races__card {
    flex-direction: row;
    &-img {
      width: 200px;
      height: 200px;
    }
    &-detail {
      margin: 0;
      justify-content: space-between;
      align-items: flex-start;
    }
    &-button {
      align-self: flex-start;
      width: 200px;
      margin: 0;
    }
  }
}
</style>