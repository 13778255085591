import api from '@/api/api';

export const dashboardRaces = {
  namespaced: true,
  state: {
    raceId: null,
    loading: false,
    error: false,
    isSidebarOpen: false,
    currentRace: JSON.parse(localStorage.getItem('currentRace')) || null,
    races: JSON.parse(localStorage.getItem('races')) || [],
    currency: 'PLN',
    currencies: [],
    totalPhotosInRace: 0,
    //orders table
    ordersData: [],
    ordersLoading: false,
    ordersError: null,
    ordersTableItemCount: 0,
    totalOrganizatorCommission: 0,
    organizatorCommissionPerCurrency: 0,
    pagination: {
      totalPages: 1,
      currentPage: 1
    },
    links: [
      { icon: 'share', text: 'dashboard.menu.shares' },
      { icon: 'shopping_cart', text: 'dashboard.main.totalOrders' },
    ],
  },
  getters: {
    getCurrentRace: (state) => {
      if (!state.currentRace && state.raceId) {
        return (
          state.races.find((race) => race.id === parseInt(state.raceId)) || null
        );
      }
      return state.currentRace;
    },
    getCurrency: (state) => state.currency,
    getCurrencies: (state) => state.currencies,
    isSidebarOpen: (state) => state.isSidebarOpen,
  },
  actions: {
    async fetchCurrencies({ commit }) {
      try {
        const response = await api.getCurrencies();
        if (response.data && response.data.data) {
          commit('setCurrencies', response.data.data);
        }
      } catch (error) {
        commit('setCurrencies', ['PLN']);
      }
    },
    async fetchUserRaces({ commit, rootState }) {
      commit('setLoading', true);
      commit('setError', false);
      try {
        const userId = rootState.auth.user.id;

        const races = await api.getUserRaces(userId);
        commit('setRaces', races.data.data);
      } catch (error) {
        commit('setRaces', []);
        commit('setError', true);
        throw error;
      } finally {
        commit('setLoading', false);
      }
    },
    async fetchRaceById({ commit, state, dispatch }, { raceId, currency }) {
      commit('setLoading', true);
      commit("setError", false);
      try {
        const currentRace = await api.getRaceDetails(raceId, currency);
        const race = state.races.find((race) => race.id === parseInt(raceId));

        const combinedRaceData = {
          ...race,
          ...currentRace.data.data,
        };

        commit('setCurrentRace', combinedRaceData);

        dispatch('fetchTotalPhotosInRace', { wpRunId: raceId });

        return combinedRaceData
      } catch (error) {
        commit("setError", true);
        commit('setCurrentRace', {});
      } finally {
        commit('setLoading', false);
      }
    },
    toggleSidebar({ commit }, isOpen) {
      commit('setSidebar', isOpen);
    },
    async fetchTotalPhotosInRace({ commit }, { wpRunId }) {
      commit('setLoading', true);
      commit("setError", false);

      try {
        const response = await api.getTotalPhotosInRace(parseInt(wpRunId));
        commit('setTotalPhotosInRace', response.data.data);
      } catch (error) {
        commit("setError", true);
        commit('setTotalPhotosInRace', 0);
      } finally {
        commit('setLoading', false);
      }
    },
    async fetchRaceOrdersData({ commit, state }, { raceId, page, perPage }) {
      commit('setOrdersLoading', true);
      commit('setOrdersError', null);

      try {
        const response = await api.getRaceOrdersTableData(raceId, page, perPage, state.currency);
        const responseData = response.data.data;
        
        const ordersData = responseData.orders || {};

        const totalTableItemsCount = responseData.totalItems || 0;
        const totalPages = responseData.totalPages || 0;
        const currentPage = responseData.currentPage || 1;

        commit('setOrdersData', Object.values(ordersData));
        commit('setTotalTableItemsCount', totalTableItemsCount);
        commit('setTotalOrganizatorCommission', responseData.totalOrganizatorCommission);
        commit('setOrganizatorCommissionPerCurrency', responseData.organizatorCommissionPerCurrency);
        commit('setPagination', { totalPages, currentPage });
      } catch (error) {
        commit('setOrdersError', error.message);
      } finally {
        commit('setOrdersLoading', false);
      }
    },
    setCurrency({ commit }, currency) {
      commit('setCurrency', currency);
    },
  },
  mutations: {
    setRaces(state, races) {
      state.races = races || [];
      localStorage.setItem('races', JSON.stringify(state.races));
    },
    setCurrentRace(state, currentRace) {
      state.currentRace = currentRace;
      localStorage.setItem('currentRace', JSON.stringify(currentRace));
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setError(state, payload) {
      state.error = payload;
    },
    setSidebar(state, isOpen) {
      state.isSidebarOpen = isOpen;
    },
    setOrdersData(state, data) {
      state.ordersData = data;
    },
    setOrdersLoading(state, loading) {
      state.ordersLoading = loading;
    },
    setOrdersError(state, error) {
      state.ordersError = error;
    },
    setTotalTableItemsCount(state, itemsCount) {
      state.ordersTableItemCount = itemsCount;
    },
    setPagination(state, pagination) {
      state.pagination = pagination;
    },
    setCurrency(state, currency) {
      state.currency = currency;
    },
    setCurrencies(state, currencies) {
      state.currencies = currencies;
    },
    setTotalOrganizatorCommission(state, totalOrganizatorCommission) {
      state.totalOrganizatorCommission = totalOrganizatorCommission;
    },
    setOrganizatorCommissionPerCurrency(state, organizatorCommissionPerCurrency) {
      state.organizatorCommissionPerCurrency = organizatorCommissionPerCurrency;
    },
    setTotalPhotosInRace(state, data) {
      state.totalPhotosInRace = data;
    },
  },
};
