import store from '@/store'
import SessionService from '@/services/session.service';
import Notify from 'quasar/src/plugins/Notify.js';
import i18n from '@/lang';

const validateDashboardRacesSingle = async (to, from, next) => {
  const raceId = to.params.raceId;
  const currency = store.getters['dashboardRaces/getCurrency']; 

  if (!raceId) {
    Notify.create({
      type: "negative",
      message: i18n.global.t('error.invalidRace'),
      position: "bottom",
    });

    return next({ name: 'dashboardRacesList' });
  }

  try {
     const raceData = await store.dispatch('dashboardRaces/fetchRaceById', { 
      raceId, 
      currency 
    });

    if (!raceData || !raceData.id) {
      Notify.create({
        type: "negative",
        message: i18n.global.t('error.invalidRace'),
        position: "bottom",
      });
      return next({ name: 'dashboardRacesList', query: { invalidRace: 'true' } });
    }

    next();
  } catch (error) {
    Notify.create({
      type: "negative",
      message: i18n.global.t('error.fetchRaces'),
      position: "bottom",
    });
    next({ name: 'dashboardRacesList', query: { fetchError: 'true' } });
  }
};

const validatePasswordReset = (to, from, next) => {
  const { key, email } = to.query;

  if (key && email) {
    SessionService.handleResetQuery(key, email);
    return next();
  }

  if (!SessionService.hasResetParams()) {
    return next('/login');
  }

  next();
};

export default {
  validatePasswordReset,
  validateDashboardRacesSingle
};