<template>
  <div>
    <ChartLine :chart-data="chartData" :chart-options="chartOptions" />
  </div>
</template>

<script>
import ChartLine from "./ChartLine.vue";

export default {
  name: "ChartTabsItem",
  components: { ChartLine },
  props: {
    data: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    backgroundColor: {
      type: String,
      default: "rgba(255, 99, 132, 0.2)",
    },
    borderColor: {
      type: String,
      default: "rgba(255, 99, 132, 1)",
    },
    timePeriod: {
      type: String,
    },
  },
  computed: {
    chartData() {
      const timeFramePrefix =
        this.timePeriod === "total"
          ? this.$t("dashboard.charts.weekLabel") + " "
          : "";
      return {
        labels: this.data.map((item) => `${timeFramePrefix} ${item.label}`),
        datasets: [
          {
            label: this.label,
            data: this.data.map((chartItem) => chartItem.value),
            backgroundColor: this.backgroundColor,
            borderColor: this.borderColor,
            borderWidth: 1,
          },
        ],
      };
    },
    chartOptions() {
      return {
        title: {
          display: true,
          text: this.title,
        },
      };
    },
  },
};
</script>